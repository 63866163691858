// Imports
// ------------
import React from 'react';
import { PageSwipeContext } from '@states/contexts';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const Logo = ({ isMenuOpen }) => {
	// NOTE • Context
	const { setPageState } = React.useContext(PageSwipeContext);

	return (
		<Jacket
			to='/'
			onClick={() => setPageState('exiting')}
			aria-label='12 Studio •  Go to homepage'
			activeClassName='active'
			style={{
				pointerEvents: isMenuOpen ? 'none' : 'auto',
				opacity: isMenuOpen ? 0 : 1,
			}}>
			<svg
				width='50'
				height='36'
				viewBox='0 0 50 36'
				xmlns='http://www.w3.org/2000/svg'>
				{/* Top Row */}
				<path className='tl' d='M9.09091 0H0V9H9.09091V0Z' />
				<path className='tml' d='M22.7271 0H13.6362V9H22.7271V0Z' />
				<path className='tmr' d='M36.3639 0H27.2729V9H36.3639V0Z' />
				<path
					className='tr'
					d='M50.0001 9H40.9092V0C45.9281 0 50.0001 4.03125 50.0001 9Z'
				/>

				{/* Middle Row */}
				<path className='ml' d='M9.09091 13.5H0V22.5H9.09091V13.5Z' />
				<path
					className='mml'
					d='M22.7271 22.5H13.6362C13.6362 17.5312 17.7082 13.5 22.7271 13.5V22.5Z'
				/>
				<path className='mmr' d='M36.3639 13.5H27.2729V22.5H36.3639V13.5Z' />
				<path
					className='mr'
					d='M40.9092 22.5V13.5H50.0001C50.0001 18.4688 45.9281 22.5 40.9092 22.5Z'
				/>

				{/* Bottom Row */}
				<path className='bl' d='M9.09091 27H0V36H9.09091V27Z' />
				<path className='bml' d='M22.7271 27H13.6362V36H22.7271V27Z' />
				<path className='bmr' d='M36.3639 27H27.2729V36H36.3639V27Z' />
				<path className='br' d='M50.0001 27H40.9092V36H50.0001V27Z' />
			</svg>
		</Jacket>
	);
};

export default Logo;
