// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
import { breakup } from '@tackl';
import { Link } from 'gatsby';

// Exports
// ------------
const pixel = keyframes`
    0%, 100% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0.2;
        transform: scale(0.95);
    }
`;

export const Jacket = styled(Link)(
	(props) => css`
		display: block;
		padding: 2.4rem;
		transition: all 0.75s ${props.theme.easing.bezzy};

		&.active {
			pointer-events: none !important;
		}

		&:hover svg {
			${breakup.large`
				.tl,
				.tml,
				.tmr,
				.tr,
				.ml,
				.mml,
				.mmr,
				.mr,
				.bl,
				.bml,
				.bmr,
				.br {
					transform-origin: bottom right;
					animation: 0.5s ${pixel} ${props.theme.easing.bezzy} both;
					animation-play-state: running;
				}

				.tl { animation-delay: 0s; }

				.tml { animation-delay: 0.1s; }

				.tmr { animation-delay: 0.2s; }

				.tr { animation-delay: 0.3s; }

				.ml { animation-delay: 0.1s; }

				.mml { animation-delay: 0.2s; }

				.mmr { animation-delay: 0.3s; }

				.mr { animation-delay: 0.4s; }

				.bl { animation-delay: 0.2s; }

				.bml { animation-delay: 0.3s; }

				.bmr { animation-delay: 0.4s; }

				.br { animation-delay: 0.5s; }
			`}
		}

		svg {
			fill: ${props.theme.colors.brand.bc4};
			width: 3.8rem;
			height: 2.7rem;
			transition: transform 0.75s ${props.theme.easing.bezzy},
				opacity 0.75s ${props.theme.easing.bezzy},
				fill 0.75s ${props.theme.easing.bezzy};

			/* NOTE • All Squares */
			.tl,
			.tml,
			.tmr,
			.tr,
			.ml,
			.mml,
			.mmr,
			.mr,
			.bl,
			.bml,
			.bmr,
			.br {
				fill: inherit;
				opacity: 1;
			}
		}
	`
);
