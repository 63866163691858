module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/index.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"small":"(min-width: 320px)","smedium":"(min-width: 375px)","medium":"(min-width: 700px)","large":"(min-width: 1024px)","xlarge":"(min-width: 1200px)","xxlarge":"(min-width: 1400px)","huge":"(min-width: 1600px)","uber":"(min-width: 1800px)","portrait":"(orientation: portrait)","horizontal":"(orientation: horizontal)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"12 Studio","short_name":"12","start_url":"/","background_color":"#121212","theme_color":"#121212","display":"fullscreen","icon":"src/images/gatsby-icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0f060b2a79ba2ea8209069f1ed754301"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
