// Imports
// ------------
import React from 'react';
import { gsap, Power1 } from 'gsap';
import { LoaderContext } from '@states/contexts';

// Styles
// ------------
import { Jacket, Loading, Number, Move } from './styles';

// Component
// ------------
const Introduction = () => {
	// NOTE • States
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [isDone, setIsDone] = React.useState(false);
	const [isRemoved, setIsRemoved] = React.useState(false);

	// NOTE • Context
	const { setIsPageLoaded } = React.useContext(LoaderContext);

	// NOTE • Refs
	const percentage = React.useRef();
	const background = React.useRef();

	// NOTE • On load, change state to 'loaded'
	React.useEffect(() => {
		let timer;

		const onPageLoad = () => {
			timer = setTimeout(() => {
				setIsLoaded(true);
			}, 500);
		};

		// Check if the page has already loaded
		if (document.readyState === 'complete') {
			onPageLoad();
		} else {
			window.addEventListener('load', onPageLoad);
			// Remove the event listener when component unmounts
			return () => window.removeEventListener('load', onPageLoad);
		}

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// NOTE • On load, animate content
	React.useEffect(() => {
		if (isLoaded) {
			gsap.fromTo(
				percentage.current,
				{
					opacity: 0.1,
					textContent: 0,
				},
				{
					duration: 2,
					opacity: 1,
					ease: Power1.easeIn,
					onComplete: () => setIsDone(true),
					textContent: 100,
					snap: { textContent: 1 },
				}
			);
		}
	}, [isLoaded]);

	React.useEffect(() => {
		if (isDone) {
			gsap.to(percentage.current, {
				duration: 0.5,
				delay: 0.25,
				opacity: 0,
				ease: Power1.easeIn,
				onComplete: () => {
					setIsPageLoaded(true);
				},
			});

			gsap.to(background.current, {
				opacity: 0,
				duration: 1,
				delay: 0.75,
				ease: Power1.easeIn,

				onComplete: () => {
					setIsRemoved(true);
				},
			});
		}
	}, [isDone]);

	return (
		<>
			{!isRemoved && (
				<Jacket ref={background}>
					<Move isLoaded={isLoaded}>
						{isLoaded && <Number ref={percentage}>100</Number>}

						<Loading isLoaded={isLoaded}>Loading...</Loading>
					</Move>
				</Jacket>
			)}
		</>
	);
};

export default Introduction;
