// Imports
// ------
import React from 'react';
import Introduction from '@parts/Introduction';
import { useHeightFix } from '@utils/useHeightFix';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '@theme';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Header from '@parts/Header';
import SmoothScroll from '@parts/SmoothScroll';
import loadable from '@loadable/component';
import { AnimatePresence, motion } from 'framer-motion';
import { PageSwipeContext, LockContext, LoaderContext } from '@states/contexts';
import PageTransitionSwipe from '@parts/PageTransitionSwipe';
import { MixpanelContext } from '@utils/tracking';

import '@css/global.css';

// Lazy Load
// ------
const Cursor = loadable(() => import('@parts/Cursor'));

// Component
// ------
const Layout = ({ children, location }) => {
	// NOTE • Vars
	const speed = 1;
	const speedConversion = speed * 1000;
	const delay = 100;

	// NOTE • Hooks
	useHeightFix();

	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • States
	const [pageState, setPageState] = React.useState('entered');
	const [canScroll, setCanScroll] = React.useState(true);
	const [isPageLoaded, setIsPageLoaded] = React.useState(false);

	// NOTE • On click of all buttons, change state to 'exiting'
	React.useEffect(() => {
		let allButtons;

		window.onpopstate = function (event) {
			if (event) {
				setPageState('exiting');
			}
		};

		const timer = setTimeout(() => {
			allButtons = document.querySelectorAll('a:not(.external)');

			allButtons.forEach((button) => {
				button.addEventListener('click', (e) => {
					setPageState('exiting');
				});
			});
		}, speedConversion + delay);

		return () => clearTimeout(timer);
	}, [location]);

	// NOTE • On page load, make page state 'entered'
	React.useEffect(() => {
		// When page loads, set page state to 'entered'

		let timer2 = setTimeout(
			() => {
				setPageState('entered');
			},
			speedConversion + delay + delay
		);

		// After delay, set page state to 'ready'
		let timer = setTimeout(() => {
			setPageState('ready');
		}, speedConversion * 2.4);

		return () => {
			clearTimeout(timer);
			clearTimeout(timer2);
		};
	}, [location]);

	// NOTE • MIXPANEL
	const mixpanel = React.useContext(MixpanelContext);

	React.useEffect(() => {
		mixpanel.track(`Page: ${location.pathname}`);
	}, [location]);

	return (
		<ThemeProvider theme={theme} key='themeprovider'>
			<LoaderContext.Provider value={{ isPageLoaded, setIsPageLoaded }}>
				<Introduction delay={speedConversion} />

				<LockContext.Provider value={{ canScroll, setCanScroll }}>
					<PageSwipeContext.Provider value={{ pageState, setPageState }}>
						<AnimatePresence mode='wait'>
							<motion.main
								style={{ overflow: 'hidden' }}
								key={location.pathname}
								initial={{ opacity: 1 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: speed }}>
								<SmoothScroll location={location} speed={speedConversion}>
									<Header location={location} />
									{bp.large ? <Cursor location={location} /> : null}

									{children}
								</SmoothScroll>
							</motion.main>
						</AnimatePresence>

						<PageTransitionSwipe speed={speed} />
					</PageSwipeContext.Provider>
				</LockContext.Provider>
			</LoaderContext.Provider>

			<GlobalStyle theme={theme} key='globalStyle' />
		</ThemeProvider>
	);
};

export default Layout;
