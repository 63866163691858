// Imports
// ------------
import React from 'react';
import Logo from '@parts/Logo';

import { PageSwipeContext } from '@states/contexts';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const PageTransitionSwipe = ({ speed, isHome }) => {
	const { pageState } = React.useContext(PageSwipeContext);

	return (
		<Jacket status={pageState} tranSpeed={speed} isHome={isHome}>
			<Logo animateReady={pageState !== 'ready'} />
		</Jacket>
	);
};

export default PageTransitionSwipe;
