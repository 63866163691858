// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket, Label, Chopsticks } from './styles';

// Component
// ------------
const MenuToggle = ({ handleClick, isMenuOpen, isFormOpen }) => (
	<Jacket
		type='button'
		onClick={handleClick}
		isOpen={isMenuOpen}
		formOpen={isFormOpen}>
		<Label data-label='Menu' isOpen={isMenuOpen}>
			Menu
		</Label>
		<Chopsticks isOpen={isMenuOpen} />
	</Jacket>
);

export default MenuToggle;
