// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { h6Styles } from '@tackl/type';

// Exports
// ------------
export const Label = styled.span(
	(props) => css`
		${h6Styles}
		display: block;
		position: relative;
		margin-right: 1.2rem;
		overflow: hidden;
		color: transparent;

		&:before,
		&:after {
			content: attr(data-label);
			position: absolute;
			top: 0;
			left: 0;
			color: ${props.isOpen
				? props.theme.colors.brand.bc3
				: props.theme.colors.brand.bc4};

			transition: transform 0.75s ${props.theme.easing.bezzy},
				color 0.75s ${props.theme.easing.bezzy};
		}

		&:before {
			transform: translateY(-112%) skewY(12deg);
		}

		&:after {
			transform: translateY(0%) skewY(0deg);
		}
	`
);

export const Chopsticks = styled.div(
	(props) => css`
		position: relative;
		width: 3.6rem;
		height: 0.2rem;

		transition: color 0.75s ${props.theme.easing.bezzy};

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;

			width: inherit;
			height: inherit;

			background: ${props.isOpen
				? props.theme.colors.brand.bc3
				: props.theme.colors.brand.bc4};
			transform-origin: center right;

			transition: transform 0.75s ${props.theme.easing.bezzy},
				background 0.75s ${props.theme.easing.bezzy};
		}

		&:before {
			transform: translateX(${props.isOpen ? -2.6 : 0}rem)
				translateY(${props.isOpen ? -1.255 : -0.3}rem)
				rotate(${props.isOpen ? 225 : 0}deg);
		}

		&:after {
			transform: translateX(${props.isOpen ? -2.6 : 0}rem)
				translateY(${props.isOpen ? 1.255 : 0.3}rem)
				rotate(${props.isOpen ? -225 : 0}deg);
		}
	`
);

export const Jacket = styled.button(
	(props) => css`
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background: transparent;
		padding: 2.4rem;
		cursor: pointer;
		pointer-events: ${props.formOpen ? 'none' : 'all'};

		&:hover {
			${breakup.large`
				${Label} {
					&:before {
						transform: translateY(0%) skewY(0deg);
					}

					&:after {
						transform: translateY(112%) skewY(12deg);
					}
				}
			`}
		}
	`
);
