// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Header } from '@tackl';

// Exports
// ------------

const sharedStyles = (props) => css`
	margin: 1.2rem;
	transition: transform 0.5s ${props.theme.easing.bezzy};

	${breakup.large`
		margin: 2.4rem;
	`}

	${props.isPos === 'down' &&
	css`
		transform: translateY(-100%);
	`}

		${props.isPos === 'up' &&
	css`
		transform: translateY(0);
	`}

		${props.isPos === 'top' &&
	css`
		transform: translateY(0);
	`}

		${props.isPos === 'bottom' &&
	css`
		transform: translateY(0);
		transition: transform 0.75s ${props.theme.easing.bezzy};

		svg {
			fill: ${props.theme.colors.brand.bc3};
		}

		button {
			span {
				&:before,
				&:after {
					color: ${props.theme.colors.brand.bc3};
				}
			}

			div {
				&:before,
				&:after {
					background: ${props.theme.colors.brand.bc3};
				}
			}
		}
	`}
`;

export const LogoJacket = styled(Header)(
	(props) => css`
		${sharedStyles}
		position: fixed;
		z-index: 12;
		top: 0;
		left: 0;
	`
);

export const ToggleJacket = styled(Header)(
	(props) => css`
		${sharedStyles}

		position: fixed;
		z-index: 12;
		top: 0;
		right: 0;

		height: 7.7rem;
		display: flex;
		justify-content: center;
		align-items: center;
	`
);
