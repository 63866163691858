// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';

// Exports
// ------------
const pixel = keyframes`
    0%, 100% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0.2;
        transform: scale(0.95);
    }
`;

export const Jacket = styled.svg(
	(props) => css`
		display: block;
		fill: ${props.theme.colors.brand.bc4};
		width: 7.6rem;
		height: 5.4rem;

		/* NOTE • All Squares */
		.tl,
		.tml,
		.tmr,
		.tr,
		.ml,
		.mml,
		.mmr,
		.mr,
		.bl,
		.bml,
		.bmr,
		.br {
			fill: inherit;
			transform-origin: bottom right;
			animation: 0.75s ${pixel} ${props.theme.easing.bezzy} infinite;
			animation-play-state: ${props.animateReady ? 'running' : 'paused'};
		}

		.tl {
			animation-delay: 0s;
		}

		.tml {
			animation-delay: 0.1s;
		}

		.tmr {
			animation-delay: 0.2s;
		}

		.tr {
			animation-delay: 0.3s;
		}

		.ml {
			animation-delay: 0.1s;
		}

		.mml {
			animation-delay: 0.2s;
		}

		.mmr {
			animation-delay: 0.3s;
		}

		.mr {
			animation-delay: 0.4s;
		}

		.bl {
			animation-delay: 0.2s;
		}

		.bml {
			animation-delay: 0.3s;
		}

		.bmr {
			animation-delay: 0.4s;
		}

		.br {
			animation-delay: 0.5s;
		}
	`
);
