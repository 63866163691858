// Imports
// ------------
import React from 'react';

// Exports
// ------------
export const ScrollContext = React.createContext();
export const PageSwipeContext = React.createContext();
export const FooterContext = React.createContext();
export const ProjectContext = React.createContext();
export const LockContext = React.createContext();
export const LoadContext = React.createContext();
export const HeaderContext = React.createContext();
export const PageState = React.createContext();
export const LoaderContext = React.createContext();
