// Imports
// ------------
import React from 'react';
import Lenis from '@studio-freight/lenis';
import { ScrollContext, LockContext } from '@states/contexts';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { CustomEase } from 'gsap/CustomEase';

// Plugin Register
// ------
if (typeof window !== 'undefined') {
	gsap.registerPlugin(ScrollTrigger, SplitText, CustomEase);
}
// Component
// ------------
const SmoothScroll = ({ children, location, speed }) => {
	// NOTE • Lenis smooth scrolling
	const lenis = React.useRef();
	const wrapperRef = React.useRef();
	const contentRef = React.useRef();

	// NOTE • Scroll Context
	const { canScroll, setCanScroll } = React.useContext(LockContext);

	// NOTE • States
	const [scrollProgress, setScrollProgress] = React.useState(0);
	const [scrollProgressAlt, setScrollProgressAlt] = React.useState(0);
	const [scrollBottom, setScrollBottom] = React.useState(false);

	// NOTE • Initialize Lenis smooth scrolling
	const initSmoothScrolling = () => {
		lenis.current = new Lenis({
			lerp: 0.06,
			wrapper: wrapperRef.current,
			content: contentRef.current,

			// easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
			// duration: 1.2,
		});

		// const scrollFn = (time) => {
		// 	lenis.current.raf(time);
		// 	requestAnimationFrame(scrollFn);
		// };

		gsap.ticker.add((time) => {
			lenis.current.raf(time * 1000);
		});

		let debounceTimer;

		const debounce = (callback, time) => {
			window.clearTimeout(debounceTimer);
			debounceTimer = window.setTimeout(callback, time);
		};

		//get scroll value
		lenis.current.on(
			'scroll',
			({ scroll, limit, velocity, direction, progress }) => {
				// ScrollTrigger.update();
				// scrollProgress = scroll;
				// scrollProgressAlt = progress;
				const ssp = setScrollProgress(scroll);
				const sspa = setScrollProgressAlt(progress);
				debounce(ssp, 200);
				debounce(sspa, 200);
				// setScrollProgress(scroll);
				// setScrollProgressAlt(progress);
			}
		);

		// Gsap scrollerProxy for lenis
		ScrollTrigger.scrollerProxy(wrapperRef.current, {
			scrollTop(value) {
				if (arguments.length) {
					lenis.current.scrollTo(value, {
						immediate: true,
					});
				}
				return lenis.current.scroll; // getter
			},
			getBoundingClientRect() {
				return {
					top: 0,
					left: 0,
					width: window.innerWidth,
					height: window.innerHeight,
				};
			},
		});
	};

	// NOTE • Activate & Destroy Lenis smooth scrolling
	React.useEffect(() => {
		initSmoothScrolling();
	}, []);

	React.useEffect(() => {
		return () => {
			lenis.current.scrollTo(0, {
				immediate: true,
			});
			lenis.current.destroy();

			setCanScroll(true);
		};
	}, [location.pathname]);

	// NOTE • Destroy Lenis if modals are open
	React.useEffect(() => {
		if (!canScroll) {
			lenis.current.stop();
		} else {
			lenis.current.start();
		}
	}, [canScroll]);

	React.useEffect(() => {
		const regex = /\/work\/[a-z0-9-]+\/?$/;

		if (location.pathname.match(regex)) {
			setCanScroll(false);
		}

		let timer = setTimeout(() => {
			setCanScroll(true);
		}, speed);

		return () => {
			clearTimeout(timer);
		};
	}, [location.pathname]);

	return (
		<ScrollContext.Provider
			value={{
				scrollProgress,
				scrollProgressAlt,
				scrollBottom,
				setScrollBottom,
				wrapperRef,
			}}>
			<div ref={wrapperRef} className='lenis-wrapper'>
				<div ref={contentRef} className='lenis-content'>
					{children}
				</div>
			</div>
		</ScrollContext.Provider>
	);
};

export default SmoothScroll;
