import React from 'react';
import mixpanel from 'mixpanel-browser';

import { MixpanelContext } from './src/utils/tracking';

export const wrapRootElement = ({ element }) => {
    mixpanel.init('1a643eacf1ef70ce6cf88eec8ab16047');
    if (process.env.NODE_ENV !== 'production') {
        mixpanel.disable();
    }

    return (
        <MixpanelContext.Provider value={mixpanel}>
            {element}
        </MixpanelContext.Provider>
    );
};