// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { h1Styles } from '@tackl/type';
import { motion } from 'framer-motion';

// Exports
// ------------

export const Jacket = styled(motion.div)(
	(props) => css`
		position: fixed;
		inset: 0;
		overflow: hidden;
		z-index: 999;
		background: ${props.theme.colors.brand.bc3};
	`
);

export const Number = styled.span`
	${h1Styles}
	color: ${(props) => props.theme.colors.global.white};

	&:after {
		content: '%';
	}
`;

export const Loading = styled.span(
	(props) => css`
		${h1Styles}
		color: ${(props) => props.theme.colors.global.white};
		opacity: ${props.isLoaded ? 0 : 0.1};
		transition: opacity 1s ${props.theme.easing.bezzy};
	`
);

export const Move = styled.div(
	(props) => css`
		position: absolute;
		bottom: 3.6rem;
		left: 3.6rem;

		display: flex;
		flex-direction: column;

		transform: translateY(${props.isLoaded ? 50 : 0}%);
		transition: transform 1s ${props.theme.easing.bezzy};

		${breakup.large`
			bottom: 6rem;
			left: 6rem;
		`}
	`
);
