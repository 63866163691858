// Imports
// ------------
import React from 'react';
import Logo from './Logo';
import MenuToggle from './MenuToggle';
import { ScrollContext } from '@states/contexts';
import { action } from 'mobx';
import { swipeState, contactFormState } from '@states';
import { observer } from 'mobx-react-lite';
import { PageSwipeContext } from '@states/contexts';
import loadable from '@loadable/component';

// Styles
// ------------
import { LogoJacket, ToggleJacket } from './styles';

// Component
// ------------
const Menu = loadable(() => import('./Menu'));

const Header = ({ location }) => {
	// NOTE • State
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState(null);
	const [prevScrollPos, setPrevScrollPos] = React.useState(0);

	// NOTE • Scroll Progress Context
	const { scrollBottom, scrollProgress } = React.useContext(ScrollContext);

	const { setPageState, pageState } = React.useContext(PageSwipeContext);

	const handleClick = () => {
		action(() => {
			swipeState.shouldSwipe = !swipeState.shouldSwipe;
		});
		setIsMenuOpen(!isMenuOpen);
	};

	React.useEffect(() => {
		if (scrollProgress <= 120) {
			setScroll('top');
		} else if (scrollProgress > prevScrollPos) {
			setScroll('down');
		} else {
			setScroll('up');
		}

		if (scrollBottom === true) {
			setScroll('bottom');
		} else {
			setScroll('top');
		}

		setPrevScrollPos(scrollProgress);
	}, [scrollProgress, scrollBottom, location]);

	// NOTE • Handle Page Swipe
	const handlePageSwipe = action(() => {
		swipeState.shouldSwipe = false;
		setPageState('exiting');
	});

	// NOTE • Small Vars
	const cf = contactFormState.isOpen;
	const ss = swipeState.shouldSwipe;

	const sharedStyles = {
		pointerEvents: cf || ss ? 'none' : 'all',
		opacity: cf ? 0 : 1,
	};

	return (
		<>
			<LogoJacket isPos={scroll} style={sharedStyles}>
				<Logo isMenuOpen={isMenuOpen} />
			</LogoJacket>

			<ToggleJacket style={sharedStyles}>
				<MenuToggle
					handleClick={handleClick}
					isMenuOpen={isMenuOpen}
					isFormOpen={cf}
				/>
			</ToggleJacket>

			<Menu isOpen={isMenuOpen} handlePageSwipe={handlePageSwipe} />
		</>
	);
};

export default observer(Header);
