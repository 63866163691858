// Imports
// ------
import { observable } from 'mobx';

// Exports
// ------
export const videoState = observable({
	isReady: false,
});

export const menuState = observable({
	isOpen: false,
	team: false,
	history: false,
	companies: false,
	legal: false,
	detailed: false,
});

export const loadingState = observable({
	isFinished: false,
});

export const swipeState = observable({
	shouldSwipe: false,
});

export const contactFormState = observable({
	isOpen: false,
});
