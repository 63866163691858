// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------------
export const Jacket = styled.div(
	(props) => css`
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: ${props.theme.colors.brand.bc2};

		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		user-select: none;
		clip-path: polygon(0% 0%, 100% -100%, 100% 100%, 0% 100%);
		/* will-change: clip-path; */

		/* RESET STATE */
		${props.status === 'ready' &&
		css`
			transform: translateY(100%);
			clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
		`}

		/* When covering the old screen */
		${props.status === 'exiting' &&
		css`
			clip-path: polygon(0% 0%, 100% -100%, 100% 100%, 0% 100%);
			transition: clip-path ${props.tranSpeed}s ${props.theme.easing.bezzy};
		`}

        /* When revealing new screen*/
        ${props.status === 'entered' &&
		css`
			clip-path: polygon(0% 0%, 100% 0%, 100% -100%, 0% 0%);
			transition: clip-path ${props.tranSpeed}s ${props.theme.easing.bezzy};
		`}
	`
);

// NOTE • All 4 corners covered exactly to the points
// clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);

// NOTE • Point top-right is less than its corner on the Y axis
// clip-path: polygon(0 0, 100% -50%, 100% 100%, 0% 100%);

// NOTE • Point bottom-right is more than its corner on the Y axis
// clip-path: polygon(0 0, 100% 00%, 100% 150%, 0% 100%);

// NOTE • Point bottom-left is less than its corner on the Y axis
// clip-path: polygon(0 0, 100% 00%, 100% 100%, 0% 50%);
